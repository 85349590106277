import React from "react";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import HiwArea from "../components/HiwArea";
import NewsSingle3 from "../components/NewsSingle3";
import DonateButton from "../components/DonateButton";

const NewsPage3 = () => {
  return (
    <Layout>
      <NavOne />
      <NewsSingle3 />
      <HiwArea /> {/* Join our movement */}
      <Newsletter />
      <DonateButton
        link={
          "https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
        }
      />
      <Footer />
    </Layout>
  );
};

export default NewsPage3;
