import React from "react";
import styled from "styled-components";
import blogImage from "../images/crowd1.jpg";

const StyledNewsItem = styled.section`
  background-color: var(--background-color2);
`;

const NewsSingle3 = () => {
  return (
    <StyledNewsItem className="causes-detail-area news-detail-area">
      <div className="container">
        <div className="row blog-content-wrap justify-content-center">
          <div className="col-lg-8">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img className="img-fluid" src={blogImage} alt="" />
                  <span className="blog__date">5 August, 2022</span>
                </div>
                <div className="blog-inner-content">
                  <div className="inner-causes-box">
                    <h3 className="blog__title">
                      Hungary's racist autocrat is a threat to American
                      democracy
                    </h3>
                    <ul className="blog__list">
                      <li className="blog__dot-active">
                        August 5, 2022 New York
                      </li>
                      <li className="blog__dot-active">Action for Democracy</li>
                      <li>Press release</li>
                    </ul>
                  </div>
                </div>
                <div className="blog-inner-content-2">
                  <p className="causes__text">
                    The evil of fascism is on the rise again, and not just in
                    Russia.
                  </p>
                  <br />
                  <p className="causes__text">
                    Today, Hungarian Prime Minister Viktor Orbán addressed the
                    Conservative Political Action Committee (CPAC) conference in
                    Dallas. This follows his speech in Romania on July 23 in
                    which he championed fundamentally fascist ideas: promoting
                    the 'great replacement' theory, professing he would defend
                    Hungary from becoming a “mixed race” country, calling
                    multi-ethnic European countries “no longer nations,” joking
                    about Nazi gas chambers, and attacking the US and the
                    European Union for sanctioning Vladimir Putin's violent
                    regime.
                  </p>
                  <br />
                  <p className="causes__text">
                    In his speech today at CPAC Orbán doubled down on his
                    fundamentally anti-democratic and xenophobic ideology. He
                    railed against the free media, vilified George Soros,
                    equated communists with liberals, and promoted culture war
                    and civilizational confrontation, all the while staying
                    silent on his close relationship to Vladimir Putin and the
                    Chinese communist leadership.
                  </p>
                  <br />
                  <p className="causes__text">
                    On behalf of the Action for Democracy Board and Advisory
                    Council we strongly condemn CPAC for inviting Viktor Orbán
                    and we are deeply concerned that his invitation will help
                    legitimize fascist ideas and further fan the flames of
                    intolerance in the US. We join Hungary's chief rabbi Róbert
                    Frölich, the International Auschwitz Committee, and many
                    others who have condemned the use of fascist terminology and
                    call upon US political leaders on both sides of the aisle to
                    condemn Orbán's hateful rhetoric.
                  </p>
                  <br />
                  <p className="causes__text">
                    Orbán's brand of nationalist populism goes against the
                    fundamental values of freedom, human dignity, and tolerance
                    on which the United States and the European Union are built
                    upon. His authoritarianism, kleptocratic governance and
                    pro-Putin ideology threaten not only to American values but
                    American security.
                  </p>
                  <br />
                  <p className="causes__text">
                    <i>
                      Kati Marton, Chair of the Action for Democracy Advisory
                      Council
                      <br />
                      David Koranyi, Board President and Executive Director,
                      Action for Democracy
                      <br />
                      Francis Fukuyama, Olivier Nomellini Senior Fellow, Center
                      on Democracy, Development and the Rule of Law, Director,
                      Ford Dorsey Masters in International Policy, Freeman
                      Spogli Institute for International Studies, Stanford
                      University and Action for Democracy Board Member <br />
                      Charles Gati, Johns Hopkins Professor and Action for
                      Democracy Board Member <br />
                      Chris Maroshegyi, Board Treasurer of Action for Democracy
                    </i>
                  </p>
                  <br />
                  <p>
                    Please contact David Koranyi for further comments at{" "}
                    <a
                      target="_blank"
                      href="mailto:david.koranyi@actionfordemocracy.org"
                    >
                      david.koranyi[at]actionfordemocracy.org
                    </a>
                  </p>
                  <br />

                  <p className="causes__text">
                    <i>
                      —“Think Global - Act Global” - Action for Democracy is
                      building a global community of concerned citizens who view
                      the rising tide of authoritarianism with increasing alarm.
                      Together we can turn back the tide by protecting democracy
                      in key battleground states, channeling your support to
                      frontline democracy organizations on the ground. Our
                      advisory council is led by Kati Marton and consists of
                      prominent leaders and defenders of democracy and human
                      rights such as Anne Applebaum, David Miliband, Zeid Ra'ad
                      Al Hussein, Timothy Snyder, Francis Fukuyama, Timothy
                      Garton Ash, Eleni Kounalakis, Rafal Trzazkowski, Charles
                      Gati, Simon Cheng, and Evelyn Farkas. Stay tuned for more
                      Action for Democracy campaigns to win the fight for
                      democracy in the very near future. actionfordemocracy.org
                      <b>#nextstopBrazil</b>
                    </i>
                  </p>
                  <div className="news-tags"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledNewsItem>
  );
};

export default NewsSingle3;
